<template>
  <div class="region">
    <div class="region__wrapper">
      <Section>
        <RegionHeader/>
      </Section>
      <Section limiter>
        <AboutRegion class="formation-portal-margin-80"/>
      </Section>
      <Section limiter>
        <Divider class="formation-portal-margin-80"/>
      </Section>
      <Section>
        <ReasonsVisitArea class="formation-portal-margin-80"
                          id="reasons"/>
      </Section>
      <Section :limiter="$viewport.desktop">
        <FormationPortal class="formation-portal-margin-80"
                         description="Знаете интересный факт или классные объекты,
          которых пока нет на карте? Давайте развивать проект вместе!"
                         title="Участвуй в формировании портала">
          <Button color="transparent"
                  :full="$viewport.mobile"
                  v-on:click.native="$openPopupAddObject">Добавить объект
          </Button>
          <Button color="transparent"
                  :full="$viewport.mobile"
                  v-on:click.native="$openPopupAddFact">
            {{`Добавить ${$viewport.desktop ? 'интересный ' : ''}факт`}}
          </Button>
        </FormationPortal>
      </Section>
      <Section>
        <InterestingFacts id="facts"/>
      </Section>
      <Divider/>
      <Section>
        <Contents id="events"/>
      </Section>
      <Section>
        <Gallery v-if="$region.images && $region.images.length > 0"
                 :params="{title: 'Фотографии Томской области', items: $region.images}"
                 id="gallery"/>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RegionView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  components: {
    RegionHeader: () => import('../components/RegionHeader'),
    AboutRegion: () => import('../components/AboutRegion'),
    ReasonsVisitArea: () => import('../components/ReasonsVisitArea'),
    InterestingFacts: () => import('../components/InterestingFacts'),
  },
  created() {
    this.$store.dispatch('GET_FACTS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
    });
  },
};
</script>
